








































export default {
  name: 'MultiSelect',
  props: ['options', 'selected'],
  data () {
    return {
      show: false,
      innerSelected: []
    }
  },
  mounted () {
    this.innerSelected = this.selected
  },
  methods: {
    toggleOptionList () {
      this.show = !this.show
    }
  },
  watch: {
    innerSelected: function (value) {
      this.$emit('update', value)
      // if (this.selected !== this.innerSelected) {
      // }
    }
  }
}
